import styled from 'styled-components'
import { Link } from 'gatsby'

const linkStyles = `
  color: blue;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }
`
export const ExternalLink = styled.a`
  ${linkStyles}
`

const StyledLink = styled(Link)`
  ${linkStyles}
`

export default StyledLink
