import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import madsobelLogo from '../../images/madsobel-logo.svg'

const LogoWrapper = styled.aside`
  text-align: center;
  position: relative;
`

const LogoImage = styled.img`
  border-radius: 50%;
  max-width: 5em;
  margin: 1.5em 0;
  border: 0.25em solid transparent;
`
const StyledLink = styled(Link)`
  &:hover,
  &:focus {
    & > img {
      border: 0.25em solid blue;
    }
    outline: none;
  }
`

function TopLogo() {
  return (
    <LogoWrapper>
      <StyledLink to="/" aria-label="Go to frontpage">
        <LogoImage src={madsobelLogo} alt="Mads Obel logo" />
      </StyledLink>
    </LogoWrapper>
  )
}

export default TopLogo
