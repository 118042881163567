import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import Header from '../Header'
import Footer from '../Footer'
import Container from '../Container'

import favIcon16 from '../../../static/img/favicon-16x16.png'
import favIcon32 from '../../../static/img/favicon-32x32.png'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  
  body {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    font-style: normal;
    font-family: consolas, monaco, courier, 'courier new', fixed-width;
    color: #333333;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  img {
    max-width: 100%;
  }
`

const GET_SITE_META_DATA = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

function Layout({ children }) {
  return (
    <StaticQuery
      query={GET_SITE_META_DATA}
      render={data => (
        <Fragment>
          <GlobalStyle />
          <Helmet
            htmlAttributes={{ lang: 'en' }}
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  'Personal website and blog for Mads Obel - I write about JavaScript, CSS, HTML and other web technologies'
              },
              {
                name: 'keywords',
                content:
                  'Mads Obel, Developer, Frontend, JavaScript, CSS, HTML, Ruby, Rails, React, Copenhagen'
              }
            ]}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favIcon16}` },
              { rel: 'shortcut icon', type: 'image/png', href: `${favIcon32}` }
            ]}
          />
          <Header />
          <Container>{children}</Container>
          <Footer />
        </Fragment>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
