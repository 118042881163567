import React from 'react'

import Container from '../Container'
import TopLogo from '../TopLogo'
import Navigation from '../Navigation'

function Header() {
  return (
    <Container>
      <TopLogo />
      <Navigation />
    </Container>
  )
}

export default Header
