/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { H1, H2 } from '../components/Heading'
import { ExternalLink } from '../components/Link'

const StyledParagraph = styled.p`
  line-height: 2;
`

function ProjectsPage() {
  return (
    <Layout>
      <H1>Projects</H1>
      <ul>
        <li>
          <H2>
            <ExternalLink
              href="https://gotaste.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              GoTaste
            </ExternalLink>
          </H2>
          <StyledParagraph>
            GoTaste is a side project I am working on with a good friend of
            mine. We both really like wine, and wine tastings. GoTaste is a
            platform for like-minded wine enthusiasts for discovering new wine
            bars, and wine tastings in Denmark
          </StyledParagraph>
          <StyledParagraph>
            All of the code we use on GoTaste is completly open source. You can
            find it on{' '}
            <ExternalLink
              href="https://github.com/gotaste/gotaste"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </ExternalLink>
          </StyledParagraph>
        </li>
      </ul>
    </Layout>
  )
}

export default ProjectsPage
