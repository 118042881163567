import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  margin: 2em 0;
  text-align: center;
`

function Footer() {
  return (
    <StyledFooter>{`© ${new Date().getFullYear()} Mads Obel`}</StyledFooter>
  )
}

export default Footer
