import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledNavList = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: center;
  margin: 0 0 2em 0;
  padding: 0;
`

const StyledNavLink = styled(Link)`
  color: #333333;
  text-decoration: none;
  padding: 1em 2em;
  font-weight: bold;

  &:hover,
  &:focus {
    color: blue;
    outline: none;
    text-decoration: underline;
  }
`

function Navigation() {
  return (
    <nav>
      <StyledNavList>
        <li>
          <StyledNavLink to="/">Home</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/projects">Projects</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/blog">Blog</StyledNavLink>
        </li>
      </StyledNavList>
    </nav>
  )
}

export default Navigation
