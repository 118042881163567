import styled from 'styled-components'

export const H1 = styled.h1`
  font-weight: bold;
  font-size: 1.6em;
  margin: 1em 0;
  line-height: 1.8em;
  text-align: ${props => (props.centered ? 'center' : 'left')};
`

export const H2 = styled.h1`
  font-weight: bold;
  font-size: 1.5em;
  margin: 1em 0;
  line-height: 1.7em;
`

export const H3 = styled.h1`
  font-weight: bold;
  font-size: 1.3em;
  margin: 1em 0;
  line-height: 1.5em;
`

export default this
